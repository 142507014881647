<template>
  <div class="requestsDashboard requestsDashboard--manualRequest requestsDashboard--admin">
    <div class="requestsDashboard__admin-content">
     <div class="requestsDashboard__header">
        <div class="requestsDashboard__title">Flight requests by date and time</div>
        <div class="requestsDashboard__nav">
          <div class="requestsDashboard__nav-item js-tabRequestsNavItem" @click="tabActivate">
            <span class="text">Current</span>
          </div>
          <div class="requestsDashboard__nav-item js-tabRequestsNavItem" @click="tabActivate">
            <span class="text">Historical</span>
          </div>
        </div>
      </div>
      <div class="requestsDashboard__content">
        <div class="requestsDashboard__content-inner">

          <div class="requestsDashboard__content-item js-tabRequestsContentItem">
            <div class="requestsDashboard__content-item-inner">
              <div class="requestsDashboard__table js-request-list">
                <div class="requestsDashboard__table-header js-request-header">
                  <div class="requestsDashboard__table-cell">№</div>
                  <div class="requestsDashboard__table-cell">Name</div>
                  <div class="requestsDashboard__table-cell">Date/time stamp</div>
                  <div class="requestsDashboard__table-cell">Take off</div>
                  <div class="requestsDashboard__table-cell">Land</div>
<!--                  <div class="requestsDashboard__table-cell">Date(s) Requested</div>-->
<!--                  <div class="requestsDashboard__table-cell">Aircraft Type</div>-->
<!--                  <div class="requestsDashboard__table-cell">Budget Goal</div>-->
                </div>
                <div class="requestsDashboard__table-body js-customScroll">
                  <vue-scroll :ops="ops">
                    <div
                      v-for="item of manualRequests"
                      :key="item.id"
                      class="requestsDashboard__table-row js-request"
                    >
                      <div class="requestsDashboard__table-info">
                        <div class="requestsDashboard__table-cell">
                          <div class="requestsDashboard__table-index">{{item.number}}</div>
                        </div>
                        <div class="requestsDashboard__table-cell">
                          <a
                            class="requestsDashboard__table-name"
                            @click="showAdditionalPopup(item.id)"
                          >
                            {{item.fullName}}
                          </a>
                        </div>
                        <div class="requestsDashboard__table-cell">{{moment(item.createdOn).format('M/D/YYYY')}}</div>
                        <div class="requestsDashboard__table-cell requestsDashboard__table-cell--from">
                          <div class="requestsDashboard__table-title">
                            <span>{{item.departure}}</span>
                            <span class="arrow"><SvgIcon name="right-arrow-2"/></span>
                          </div>
                        </div>
                        <div class="requestsDashboard__table-cell requestsDashboard__table-cell--to">
                          <div class="requestsDashboard__table-title">{{item.arrival}}</div>
                        </div>
<!--                        <div class="requestsDashboard__table-cell">-->
<!--                          <div class="requestsDashboard__table-title">{{!item.returnDate ? moment(item.departureDate).format('M/D/YYYY') : moment(item.departureDate).format('M/D/YYYY') + '-' + moment(item.returnDate).format('M/D/YYYY')}}</div>-->
<!--                        </div>-->
<!--                        <div class="requestsDashboard__table-cell">-->
<!--                          <div class="requestsDashboard__table-title">{{item.aircraftType}}</div>-->
<!--                        </div>-->
<!--                        <div class="requestsDashboard__table-cell">-->
<!--                          <div class="requestsDashboard__table-title">{{item.maxPrice ? item.maxPrice : ''}}</div>-->
<!--                        </div>-->
                      </div>
                      <a href="mailto:FLY@flyjets.com" class="requestsDashboard__table-message">
                        <SvgIcon name="mail-footer"/>
                      </a>
                      <div class="requestsDashboard__table-status">
                        <div class="requestsDashboard__table-status-item">Request status: <b>{{ item.statusValue }}</b></div>
                        <span
                          class="requestsDashboard__table-additional js-popup"
                          @click="showAdditionalPopup(item.id)"
                        >
                          Additional info
                        </span>
                      </div>
                    </div>
                  </vue-scroll>
                </div>
              </div>
            </div>
          </div>

          <div class="requestsDashboard__content-item js-tabRequestsContentItem">
            <div class="requestsDashboard__content-item-inner">
              <div class="requestsDashboard__table js-request-list">
                <div class="requestsDashboard__table-header js-request-header">
                  <div class="requestsDashboard__table-cell">№</div>
                  <div class="requestsDashboard__table-cell">Name</div>
                  <div class="requestsDashboard__table-cell">Date/time stamp</div>
                  <div class="requestsDashboard__table-cell">Take off</div>
                  <div class="requestsDashboard__table-cell">Land</div>
                </div>
                <div class="requestsDashboard__table-body js-customScroll">
                  <vue-scroll :ops="ops">
                    <div
                      v-for="item of historicalManualRequests"
                      :key="item.id"
                      class="requestsDashboard__table-row js-request"
                    >
                      <div class="requestsDashboard__table-info">
                        <div class="requestsDashboard__table-cell">
                          <div class="requestsDashboard__table-index">{{item.number}}</div>
                        </div>
                        <div class="requestsDashboard__table-cell">
                          <a
                            class="requestsDashboard__table-name"
                            @click="showAdditionalPopup(item.id)"
                          >
                            {{item.fullName}}
                          </a>
                        </div>
                        <div class="requestsDashboard__table-cell">{{moment(item.createdOn).format('M/D/YYYY')}}</div>
                        <div class="requestsDashboard__table-cell requestsDashboard__table-cell--from">
                          <div class="requestsDashboard__table-title">
                            <span>{{item.departure}}</span>
                            <span class="arrow"><SvgIcon name="right-arrow-2"/></span>
                          </div>
                        </div>
                        <div class="requestsDashboard__table-cell requestsDashboard__table-cell--to">
                          <div class="requestsDashboard__table-title">{{item.arrival}}</div>
                        </div>
                      </div>
                      <a href="mailto:FLY@flyjets.com" class="requestsDashboard__table-message">
                        <SvgIcon name="mail-footer"/>
                      </a>
                      <div class="requestsDashboard__table-status">
                        <div class="requestsDashboard__table-status-item">Request status: <b>{{ item.statusValue }}</b></div>
                        <span
                          class="requestsDashboard__table-additional js-popup"
                          @click="showAdditionalPopup(item.id)"
                        >
                          Additional info
                        </span>
                      </div>
                    </div>
                  </vue-scroll>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <RequestsDetailPopup :justInfo="true"/>
    <DashboardPreloader/>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import RequestsDetailPopup from '@/components/common/modals/RequestsDetailPopup'
  import DashboardPreloader from '@/views/dashboard/common/DashboardPreloader/DashboardPreloader'
  import moment from 'moment'
  import '@/views/dashboard/common/Requests/Requests.styl'

  export default {
    props: ['dashboardType'],
    name: 'ManualRequests',
    data: () => ({
      tabs: new Tabs('.js-tabRequestsNavItem', '.js-tabRequestsContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      manualRequests () {
        return this.$store.state.booking.manualRequests
      },
      historicalManualRequests () {
        return this.$store.state.booking.historicalManualRequests
      },
    },
    created () {
      setTimeout(() => {
        const preloader = document.querySelector('.dashboardPreloader')
        preloader && preloader.classList.add('dashboardPreloader--hide')
      }, 0)
    },
    mounted () {
      this.$store.commit('setManualRequests')
      this.$store.commit('setHistoricalManualRequests')
      this.tabs.addActiveForMouted()
    },
    methods: {
      moment,
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
      showAdditionalPopup (id) {
        const additionalInfo = this.manualRequests.find(item => item.id === id)
        this.$modal.show('RequestsDetailPopup', {additionalInfo: additionalInfo})
      },
    },
    components: {
      SvgIcon,
      RequestsDetailPopup,
      DashboardPreloader,
    },
  };
</script>
